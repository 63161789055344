<script>
	import { terms } from '$stores';
	import { CtaButton } from '$lib/components/ctas';
	import { faCalendarClock } from '@awesome.me/kit-065f90d5b1/icons/classic/regular';

	export let theme = 'blue';
	export let size = 'large';
</script>

<CtaButton {size} {theme} icon={faCalendarClock} href="https://scheduler.servicetitan.com/book/eyJicmFuZElkIjoiYnJhbmRfaG14MDFwNjc0N3hxMXF3OHpoZnR6bnBtIiwic2NoZWR1bGVySWQiOiJzY2hlZF95Y2ZxOXpkempoNHl1eGNnNjMyYnJocmQifQ%3D%3D" target="_blank" rel="nofollow">
	{terms.ctas.form}
</CtaButton>
