<script>
	import { classParser } from '$stores';
	import { FontAwesomeIcon } from '@fortawesome/svelte-fontawesome';

	export let icon = undefined;
	export let href = undefined;

	export let size = 'large';
	export let layout = 'normal';
	export let theme = 'blue';

	const classes = classParser({
		classVars: { size, layout, theme, classProps: $$props.class },
		classOptions: {
			size: {
				large: 'text-lg',
				small: 'text-sm'
			},
			layout: {
				normal: 'flex place-items-center place-content-center font-headline font-semibold focus:ring-4 duration-300 text-center space-x-2 rounded py-2.5 px-4  whitespace-nowrap'
			},
			theme: {
				blue: 'text-white bg-tee-blue-default hover:bg-tee-blue-s10 focus:ring-blue-300',
				light: 'text-tee-blue-default bg-tee-blue-t95 border border-tee-blue-default hover:bg-tee-blue-t90 hover:border-tee-blue-t40 focus:ring-blue-300'
			}
		}
	});
</script>

{#if href}
	<a {href} class={classes} {...$$restProps}>
		{#if icon}<FontAwesomeIcon {icon} size="1x" fixedWidth />{/if}
		<span><slot /></span>
	</a>
{:else}
	<button type="button" class={classes} on:click on:keyup>
		{#if icon}<FontAwesomeIcon {icon} size="1x" fixedWidth />{/if}
		<span><slot /></span>
	</button>
{/if}
